<template>
  <base-layout>
    <ion-content id="type-detail-page" class="has-header has-subheader">
      <div class="tag-title" style="padding: 8px; font-size: 18px;" :style="{'background-color': tag.color, 'color': tag.font_color}" >{{ tag.title }} </div>
      <session-list :sessions="sessions"></session-list>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent} from "@ionic/vue";
import {mapActions} from "vuex";
import SessionList from "@/components/SessionList"

export default {
  data() {
    return {
      tag: {},
      sessions: []
    };
  },
  components: {
    IonContent,
    "session-list": SessionList
  },
  methods: {
    ...mapActions("sessiontypes", ["getTag", "getSessionsByTag"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
  },
  async created() {
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Tag Detail') {
          this.tag = await this.getTag(this.$route.params.id);
          this.trackWithLabel(this.tag.title)
          this.sessions = await this.getSessionsByTag(this.tag.id);
            this.sessions = this.sessions.sort((a, b) => {
              return a.title.localeCompare(b.title);
            }).sort((a, b) => {

              return new Date(a.start_time) - new Date(b.start_time);
            });
        }
      }
    }
  }
};
</script>
<style scoped>
#type-detail-page {
  --padding-top: 10px;
  --padding-bottom: 10px;
}
</style>
